<template>
  <AdminLK v-if="isManager && !getFakeUser"/>
  <Applicants v-else-if="(isManager && getFakeUser) || !isExpert"/>
</template>

<script>
import {mapGetters} from "vuex";
import Applicants from "@/components/applicants/Applicants";
import AdminLK from "@/components/applicants/admin/main/AdminLK";

export default {
  name: "ApplicantsView",
  components: {AdminLK, Applicants},
  computed: {
    ...mapGetters('keycloak', {
      getFakeUser: 'getFakeUser',
      isManager: 'isManager',
      isExpert: 'isExpert',
    }),
  },
  created() {
    if (this.isExpert && !this.isManager) {
      this.$router.push({name: 'ApplicantsSelection'});
    }
  }
}
</script>

<style scoped>

</style>