export const tablePagination = {
  data() {
    return {
      page: 1,
      userPages: 10,
      pagesPaginate: [
        { value: 10, text: 10 },
        { value: 25, text: 25 },
        { value: 50, text: 50 },
        { value: 10000, text: 'все' },
      ],
    };
  },
  watch: {
    lengthData(newValue) {
      if (newValue < 50) this.userPages = 10000;
      else this.userPages = 25;
    },
  },
  computed: {
    showingServerData() {
      return this.showingData.slice((this.page - 1) * this.userPages, this.page * this.userPages);
    },
    pagesVuetify() {
      return Math.ceil(this.showingData.length / this.userPages);
    },
    // lengthData() {
    //   return this.serverData.length
    // }
  },
  methods: {
    changePage() {
      this.loadingData = true;
      setTimeout(() => {
        this.loadingData = false;
      }, 300);
    },
  },
};
