// eslint-disable-next-line no-unused-vars
const ApplicantsMainJson = (_this) => {
  return {
    countCells: 18,
    orgMassive: [
      {
        dataField: 'fio',
        cols: 4,
        rows: 1,
        text: 'ФИО',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantJsonFio',
        styles: '',
        headerClass: '',
        headerStyles: `display: none`,
      },
      {
        dataField: 'info',
        cols: 4,
        rows: 1,
        text: 'Информация',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantJsonInfo',
        styles: '',
        headerStyles: `display: none`,
      },
      {
        dataField: 'spec',
        cols: 4,
        rows: 1,
        text: 'Специальность',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantJsonSpec',
        styles: '',
        headerStyles: `display: none`,
      },
      {
        dataField: 'docs',
        text: '',
        cols: 1,
        rows: 1,
        variable: true,
        class: 'applicantJsonDocs',
        styles: '',
        headerStyles: `display: none`,
      },
      {
        dataField: 'stpType',
        cols: 3,
        rows: 1,
        text: 'Тип стипендии',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantJsonStpType',
        styles: '',
        headerStyles: `display: none`,
      },
      {
        dataField: 'actions',
        text: '',
        cols: 2,
        rows: 1,
        variable: true,
        styles: 'border-left: none; display: flex; flex-direction: row',
        headerStyles: `display: none`,
      },
    ],
  };
}

export default ApplicantsMainJson